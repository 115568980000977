import axios from 'axios';
import getAuthToken from '../utils/cookie';

export const getSubmissionsDataApi = async () => {
  let response;
  const tok = getAuthToken();
  try {
    response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}get/submission/`, {
      headers: {
        Authorization: `Bearer ${tok}`,
      },
    });
  } catch (err) {
    throw err
  }

  return response;
};

export const getSubmissionsLongDataApi = async () => {
  let response;
  const tok = getAuthToken();
  try {
    response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}get/submission/long/polling`, {
      headers: {
        Authorization: `Bearer ${tok}`,
      },
    });
  } catch (err) {
    throw err
  }

  return response;
};

export const updateSubmissionDataApi = async ({ data }) => {
  let response;
  const tok = getAuthToken();
  try {
    response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}update/submission/`, data, {
      headers: {
        Authorization: `Bearer ${tok}`,
      },
    });
  }
  catch (err) {
    window.location.reload();
  }
  if (!response.status === 200) {
    window.location.reload();
  }
  return response;
}

export const deleteSubmissionDataApi = async ({ submissionId }) => {
  let response;
  const tok = getAuthToken();
  try {
    response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/delete/submission/?submission_id=${submissionId}`, {
      headers: {
        Authorization: `Bearer ${tok}`,
      },
    });
  }
  catch (err) {
    window.location.reload();
  }
  if (!response.status === 200) {
    window.location.reload();
  }

  return response;
}

export const downloadSubmissionDataApi = async ({ submissionId }) => {
  let response;
  const tok = getAuthToken();
  try {
    response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}document/by/submission/id?id=${submissionId}`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${tok}`,
      },
    });
  }
  catch (err) {
    window.location.reload();
  }
  if (!response.status === 200) {
    window.location.reload();
  }

  return response;
}

export const addSubmissionDataApi = async ({ data }) => {
  let response;
  const tok = getAuthToken();
  try {
    response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}create/submission/`, data, {
      headers: {
        Authorization: `Bearer ${tok}`,
      },
    });
  }
  catch (err) {
    window.location.reload();
  }
  if (!response.status === 200) {
    window.location.reload();
  }

  return response;
}





