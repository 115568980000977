import { createContext, useContext, useState } from "react";

const initialState = {
  name: "",
  email: "",
  image: "",
  tokenId: "",
  status: "",
  site_tour: "",
  action: null,
  canceled: false,
  start_date: null,
  current_period_start: null,
  current_period_end: null,
  book_tokens: 0,
  script_tokens: 0,
  extra_book_tokens: 0,
  extra_script_tokens: 0,
  custom_script_template: null,
  custom_book_template: null,
  show_chat: false,
};

export const UserDetailsContext = createContext({
  userDetails: initialState,
  updateUserDetails: () => {},
  updateUserTokens: () => {}
});

export const useUserDetialsContext = () => useContext(UserDetailsContext);

/* eslint-disable react/prop-types */
export function UserDetailsContextProvider({ children }) {
  const [userDetails, setUserDetails] = useState(initialState);

  const updateUserDetails = ({
    name = '',
    email = '',
    image = null,
    tokenId = "",
    status = "",
    site_tour = "",
    action = null,
    canceled = false,
    start_date = null,
    canceled_at = null,
    current_period_start = null,
    current_period_end = null,
    book_tokens = 0,
    script_tokens = 0,
    extra_book_tokens = 0,
    extra_script_tokens = 0,
    custom_script_template = null,
    custom_book_template = null,
    show_chat = false
  }) => {
    setUserDetails({
      ...userDetails,
      name,
      email,
      image,
      tokenId,
      status,
      site_tour,
      action,
      canceled,
      start_date,
      canceled_at,
      current_period_start,
      current_period_end,
      book_tokens,
      script_tokens,
      extra_book_tokens,
      extra_script_tokens,
      custom_book_template,
      custom_script_template,
      show_chat
    });
  };

  const updateUserTokens = (book_tokens, script_tokens) => {
    if (book_tokens === 0) {
      if (
        userDetails.script_tokens === 0
        && userDetails.extra_script_tokens === 0
      ) {
        return;
      }
      if (userDetails.extra_script_tokens > 0) {
        setUserDetails((prev) => ({
          ...prev,
          extra_script_tokens: prev.extra_script_tokens + script_tokens
        }));
        return;
      }

      setUserDetails((prev) => ({
        ...prev,
        script_tokens: prev.script_tokens + script_tokens
      }));
    } else {
      if (
        userDetails.book_tokens === 0
        && userDetails.extra_book_tokens === 0
      ) {
        return;
      }
      if (userDetails.extra_book_tokens > 0) {
        setUserDetails((prev) => ({
          ...prev,
          extra_book_tokens: prev.extra_book_tokens + book_tokens
        }));
        return;
      }
      setUserDetails((prev) => ({
        ...prev,
        book_tokens: prev.book_tokens + book_tokens
      }));
    }
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <UserDetailsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ userDetails, updateUserTokens, updateUserDetails }}
    >
      {children}
    </UserDetailsContext.Provider>
  );
}
