import { createContext, useContext, useState } from 'react';

export const SubmissionsContext = createContext({
  submissionsData: [],
  setSubmissionData: () => {},
  hiddenColumns: [],
  setHiddenColumns: () => {},
  loading: true,
  setLoading: () => {}
});

export const useSubmissionsContext = () => useContext(SubmissionsContext);

export function SubmissionsContextProvider({ children }) {
  const [submissionsData, setSubmissionsData] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const setSubmissionData = (data) => {
    setSubmissionsData(data);
  };

  return (
    <SubmissionsContext.Provider
      value={{
        submissionsData,
        setSubmissionData,
        hiddenColumns,
        setHiddenColumns,
        loading,
        setLoading
      }}>
      {children}
    </SubmissionsContext.Provider>
  );
}
