import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import sideBarItems from '../../static/data/SidebarItems';
import RenderMultiOption from './RenderMultiOption';
import { useUserDetialsContext } from '../../context/user-details';

const NavLinks = () => {
  const pathname = window.location.pathname.substring(1) || 'script-coverage';
  // To keep the billing, settings, and polices open by default
  const [openedItemsId, setOpenedItemsId] = useState([4, 5, 7]);
  const {
    userDetails: { show_chat }
  } = useUserDetialsContext();

  const multiOptionClickHandler = (itemId) => {
    if (openedItemsId?.includes(itemId)) {
      const newOpenedItemsId = openedItemsId?.filter((id) => id !== itemId);
      localStorage.setItem('openItemsId', JSON.stringify(newOpenedItemsId));
      setOpenedItemsId(newOpenedItemsId);
    } else {
      const newOpenedItemsId = [...openedItemsId];
      newOpenedItemsId.push(itemId);
      localStorage.setItem('openItemsId', JSON.stringify(newOpenedItemsId));
      setOpenedItemsId(newOpenedItemsId);
    }
  };

  const renderSideBarItems = () => {
    return sideBarItems.map((item, index1) => {
      if (show_chat == false && item.linkTo == 'query') return;
      const isActive = pathname === item?.linkTo;
      if (item.isMultiOption) {
        const isOpened = openedItemsId?.includes(item.id);
        return (
          <RenderMultiOption
            item={item}
            isActive={isActive}
            isOpened={isOpened}
            onClick={() => multiOptionClickHandler(item?.id)}
          />
        );
      }
      return (
        <li key={item.id}>
          <NavLink
            to={item.linkTo}
            exact="true"
            className={`flex items-center gap-2 px-2.5 py-2 w-full leading-6 ${
              isActive
                ? 'bg-primary font-Inter rounded-xl'
                : 'bg-transparent hover:!text-secondary hover:border-l-secondary'
            } border-2 border-transparent transition-all duration-100 cursor-pointer group`}>
            {item.icon(isActive)}
            <span className="w-full text-[13px] font-semibold">{item?.value}</span>
          </NavLink>
          {/* A gray line after item with id of 8 (draft comparison) */}
          {item.id === 8 && <div className={`h-[0.0625rem] my-3 bg-[#1D2939]`} />}
        </li>
      );
    });
  };

  useEffect(() => {
    const storedOpenItemsId = localStorage.getItem('openItemsId');
    if (storedOpenItemsId) {
      setOpenedItemsId(JSON.parse(storedOpenItemsId));
    }
  }, []);

  return (
    <div className="w-full">
      <ul className="flex flex-col gap-2 text-white w-full h-full font-Inter">
        {renderSideBarItems()}
      </ul>
    </div>
  );
};

export default NavLinks;
