import { Link } from 'react-router-dom';
import { SpinnerSmall } from '../spinner/Spinner';

/* eslint-disable react/prop-types */
const Button = ({
  element = null,
  to = '',
  id,
  className,
  onClick,
  type,
  disabled = false,
  children,
  loading = false
}) => {
  const returnButtonSpinner = () => {
    if (loading) {
      return <SpinnerSmall className="!h-4 !w-4" secondPathColor={'rgb(224,139,108)'} />;
    }
  };

  if (element === 'link') {
    return (
      <Link
        to={!disabled ? to : ''}
        className={`${className} p-2 md:py-3 md:px-5 ${!disabled ? 'bg-primary' : 'bg-[rgb(224,139,108)]'} text-white ${!disabled && 'hover:bg-primary-dark'} h-fit w-fit transition-all duration-200 rounded-md`}
        id={id || ''}
        onClick={onClick}>
        {children}
      </Link>
    );
  }
  return (
    <button
      className={`${className} p-2 md:py-3 md:px-5 ${!disabled ? 'bg-primary' : 'bg-[rgb(224,139,108)]'} text-white ${!disabled && 'hover:bg-primary-dark'} h-fit w-fit transition-all duration-200 rounded-md`}
      id={id || ''}
      // eslint-disable-next-line react/button-has-type
      type={type || 'button'}
      disabled={disabled}
      onClick={onClick}>
      <div className="flex items-center gap-1.5">
        {children}
        {returnButtonSpinner()}
      </div>
    </button>
  );
};

export default Button;
