import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { updateSubmissionDataApi } from '../../api/submissions-database';
import { useSubmissionsContext } from '../../context/submissions';
import returnTextDots from '../../utils/returnTextDots';
import TableInputField from './TableInputField';
import TableTextAreaField from './TableTextAreaField';

const SingleEntryField = ({
  defaultValue,
  fieldName,
  item,
  editingField,
  setEditingField,
  lineBreakLength,
  isTextArea,
  fieldWidth,
  isProcessingOrFailed
}) => {
  const status = item?.status?.toLowerCase();
  const { setSubmissionData } = useSubmissionsContext();
  const [editingFieldValue, setEditingFieldValue] = useState(defaultValue);
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(isTextArea);

  const updatedSubmissionsFieldValue = async () => {
    setSubmissionData((prev) =>
      prev.map((data) => {
        if (data._id === item._id) {
          return {
            ...data,
            [editingField.field]: editingFieldValue
          };
        }
        return data;
      })
    );

    setIsTextAreaFocused(false);
    setEditingField({});
    updateSubmissionDataApi({
      data: { _id: item._id, [fieldName]: editingFieldValue }
    });
  };

  const keyDownHandler = async (e) => {
    if (e.key === 'Enter') {
      updatedSubmissionsFieldValue();
    }
    if (e.key === 'Escape') {
      setIsTextAreaFocused(false);
      setEditingFieldValue(defaultValue);
      setEditingField({});
    }
  };

  const onBlurHandler = async () => {
    updatedSubmissionsFieldValue();
  };

  const fieldClickHandler = (field) => {
    if (isProcessingOrFailed) return;
    setEditingField({
      field,
      itemId: item._id
    });
    setTimeout(() => {
      const input = document.getElementById(`${field}-${item._id}`);
      if (input) {
        input.focus();
      }
    }, 100);
  };

  const returnTextAreaRows = () => {
    if (editingFieldValue) {
      if (lineBreakLength && editingFieldValue.length > lineBreakLength) {
        return Math.ceil(editingFieldValue.length / lineBreakLength) - 1;
      }
      if (editingFieldValue.length > 30) {
        return Math.ceil(editingFieldValue.length / 30);
      }
      return 1;
    } else {
      return 1;
    }
  };

  const isFieldBeingEdited = editingField.field === fieldName && editingField.itemId === item._id;

  if (isTextArea && isFieldBeingEdited) {
    return (
      <TableTextAreaField
        value={isTextAreaFocused ? editingFieldValue : returnTextDots(12, editingFieldValue) || ''}
        onChange={(value) => setEditingFieldValue(value)}
        onKeyDown={keyDownHandler}
        onBlur={onBlurHandler}
        onFocus={() => setIsTextAreaFocused(true)}
        onTextAreaFocus={fieldClickHandler}
        editingField={editingField}
        id={`${fieldName}-${item._id}`}
        style={{ width: '100%' }}
        rows={returnTextAreaRows()}
      />
    );
  }
  if (isFieldBeingEdited) {
    return (
      <TableInputField
        value={editingFieldValue}
        onChange={(value) => setEditingFieldValue(value)}
        onKeyDown={keyDownHandler}
        onBlur={onBlurHandler}
        editingField={editingField}
        isSingleEntry
        id={`${fieldName}-${item._id}`}
      />
    );
  }

  if (!(item[fieldName]?.length > 0)) {
    return (
      <span onClick={() => fieldClickHandler(fieldName)} className="cursor-pointer">
        ---
      </span>
    );
  }

  const returnFieldWidth = () => {
    if (item[fieldName]?.length > lineBreakLength) {
      return lineBreakLength + 'ch';
    } else {
      return item[fieldName]?.length - 4 + 'ch';
    }
  };

  if (lineBreakLength) {
    const containerStyle = {
      maxHeight: lineBreakLength * 2 + 'px', // Maximum height
      minWidth: returnFieldWidth(),
      overflow: 'hidden', // Ensure overflow is hidden
      textOverflow: 'ellipsis', // Add ellipsis for overflowing text
      wordWrap: 'break-word', // Ensure words don't break mid-word
      overflowWrap: 'break-word' // Ensure words don't break mid-wor
    };

    return (
      <Tooltip title={item[fieldName]} enterDelay={500} leaveDelay={200} placement="top">
        <div
          onClick={() => fieldClickHandler(fieldName)}
          className={`${status !== 'processing' && 'cursor-pointer'} text-xs`}
          style={containerStyle}>
          <p className="whitespace-normal w-full">{item[fieldName]}</p>
        </div>
      </Tooltip>
    );
  }

  const displayFieldStyles = {
    maxWidth: fieldWidth ? fieldWidth : 'fit-content',
    width: 'max-content',
    textWrap: 'wrap',
    overflow: 'hidden'
  };

  return (
    <Tooltip
      title={item[fieldName]}
      enterDelay={500}
      leaveDelay={200}
      placement="top"
      className="w-fit">
      <div
        onClick={() => fieldClickHandler(fieldName)}
        className={`text-xs w-fit`}
        style={displayFieldStyles}>
        <p>{item[fieldName]}</p>
      </div>
    </Tooltip>
  );
};
export default SingleEntryField;
