import { useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Outlet, useNavigate } from 'react-router-dom';
import { getSubmissionsDataApi } from '../api/submissions-database';
import { useSubmissionsContext } from '../context/submissions';
import { sortDataByFields } from '../utils/sortDataByFields';

import MainNavigation from '../components/Navigation/MainNavigation';
import CardInputModal from '../components/billing/modal/CardInputModal';
import ConfirmationModal from '../components/billing/modal/ConfirmationModal';
import { useUserDetialsContext } from '../context/user-details';
import { confirmPayment } from '../utils/billing/confirmPayment';
import confirmSetupIntent from '../utils/billing/confirmSetupIntent';
import getAuthToken from '../utils/cookie';
import redirectToLogin from '../utils/token';
import { verify } from '../utils/verify';

function MainLayout() {
  const { updateUserDetails } = useUserDetialsContext();
  const { setLoading, setSubmissionData } = useSubmissionsContext();

  const navigate = useNavigate();
  const stripe = useStripe();

  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [showCardModal, setShowCardModal] = useState(false);
  const [isTrialingIntent, setIsTrialingIntent] = useState(false);
  const [user, setUser] = useState({
    name: '',
    email: '',
    image: null
  });

  const suretyClickHandler = (choice) => {
    const isYes = choice === 'yes';
    setConfirmationModal(false);
    if (isYes) {
      if (!stripe || !paymentMethodId || !clientSecret) {
        console.log('Stripe, PaymentMethodId, or ClientSecret is null');
        return;
      }
      if (isTrialingIntent) {
        confirmSetupIntent(stripe, clientSecret, paymentMethodId);
      } else {
        confirmPayment(stripe, navigate, clientSecret, paymentMethodId);
      }
    }
  };

  const setAndSortSubmissionsList = (submissionList) => {
    const sortCriteria = localStorage.getItem('sortingCriteria');
    if (sortCriteria) {
      const sortedData = sortDataByFields(submissionList, JSON.parse(sortCriteria));
      return sortedData;
    } else {
      return submissionList;
    }
  };

  const fetchSubmissionData = async () => {
    try {
      setLoading(true);
      const response = await getSubmissionsDataApi();
      const submissionList = response?.data?.data;
      const submissionSorted = setAndSortSubmissionsList(submissionList);
      setSubmissionData(submissionSorted);
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      if (error?.response?.status === 401) {
        toast.error('Session expired. Please log in again to continue.');
        setTimeout(() => {
          redirectToLogin();
        }, 2000);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const tokenValue = getAuthToken();
      if (!tokenValue) {
        return redirectToLogin();
      }

      const data = await verify(tokenValue);
      if (!data) {
        return redirectToLogin();
      }

      const {
        email,
        given_name,
        status,
        custom_book_template,
        custom_script_template,
        site_tour,
        book_token,
        script_token,
        extra_book_tokens,
        extra_script_tokens,
        action,
        start_date,
        canceled,
        current_period_start,
        canceled_at,
        current_period_end,
        show_chat
      } = data;

      const baseUserDetails = {
        name: given_name,
        email,
        image: null,
        tokenId: tokenValue,
        status,
        custom_book_template,
        custom_script_template,
        site_tour,
        show_chat
      };

      setUser({ name: given_name, email, image: null });

      const handlePaymentAction = () => {
        if (action === 'requires_payment_method') {
          const { card, client_secret } = data;
          if (card) {
            setClientSecret(client_secret);
            setShowCardModal(true);
          } else {
            toast.error(
              'Please update your card details. https://portal.fullfr.me/p/login/8wM6s5edibn46vm5kk'
            );
          }
        } else if (action === 'requires_action') {
          const { client_secret, payment_method } = data;
          setClientSecret(client_secret);
          setPaymentMethodId(payment_method);
          setConfirmationModal(true);
          if (status === 'trialing') {
            setIsTrialingIntent(true);
          }
        }
      };

      const extendedUserDetails = {
        ...baseUserDetails,
        canceled,
        start_date,
        current_period_start,
        canceled_at,
        current_period_end,
        script_tokens: script_token,
        book_tokens: book_token,
        extra_book_tokens,
        extra_script_tokens
      };

      switch (status) {
        case 'not_subscribed':
          updateUserDetails(baseUserDetails);
          break;
        case 'canceled':
          updateUserDetails(extendedUserDetails);
          break;
        case 'past_due':
        case 'incomplete':
        case 'trialing':
          handlePaymentAction();
          updateUserDetails({ ...extendedUserDetails, action });
          break;
        default:
          updateUserDetails(extendedUserDetails);
      }
    };
    fetchData().then(() => {
      fetchSubmissionData();
    });
  }, []);

  return (
    <main id="pageContainer" className="w-full z-20 h-screen full-wrapper overflow-y-scroll">
      <div>
        <MainNavigation />
        <Outlet />
        <ConfirmationModal
          confirmationModal={confirmationModal}
          setConfirmationModal={setConfirmationModal}
          suretyClickHandler={suretyClickHandler}
          subHeading="Confirm Subscription"
          message="Please confirm you would like to start your paid subscription for $25? You will be charged immediately and receive an additional 10 script credits and 5 book credits."
        />
        <CardInputModal
          clientSecret={clientSecret}
          showCardModal={showCardModal}
          setShowCardModal={setShowCardModal}
        />
      </div>
    </main>
  );
}

export default MainLayout;
