import { useState } from 'react';

import axios from 'axios';
import { toast } from 'react-hot-toast';

import { useUserDetialsContext } from '../../context/user-details';
import getAuthToken from '../../utils/cookie';
import redirectToLogin from '../../utils/token';

import Button from '../../components/UI/Button';
import Card from '../../components/UI/Card';
import FileUpload from '../../components/coverage/FileUpload';
import ConfirmationModal from '../../components/coverage/Modal/ConfirmationModal';
import MessageModal from '../../components/coverage/Modal/MessageModal';
import ReportGenerationModal from '../../components/coverage/Modal/ReportGenerationModal';
import { useSubmissionsContext } from '../../context/submissions';

const BookCoverage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [reportGenerationModal, setReportGenerationModal] = useState(false);
  const [reportGeneration, setReportGeneration] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const { setSubmissionData } = useSubmissionsContext();

  const { updateUserTokens } = useUserDetialsContext();

  const suretyClickHandler = (choice) => {
    const isYes = choice === 'yes';
    setConfirmationModal(false);
    if (isYes) {
      let isTimeOut = false;
      setTimeout(() => {
        isTimeOut = true;
      }, 240000);

      setReportGenerationModal(true);
      setReportGeneration(true);
      updateUserTokens(-1, 0);
      const formData = new FormData();
      formData.append('file', selectedFile);
      const tok = getAuthToken();
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}generate_book/`, formData, {
          method: 'POST',
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${tok}`
          }
        })
        .then((res) => {
          setReportGeneration(false);
          setReportGenerationModal(false);
          const url = window.URL.createObjectURL(
            new Blob([res.data], {
              type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            })
          );
          const link = document.createElement('a');
          link.href = url;

          const { filename } = res.headers;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          setReportGeneration(false);
          setReportGenerationModal(false);

          if (isTimeOut) {
            setShowMessage(true);
            return;
          }

          console.log('error', error);

          updateUserTokens(1, 0);

          if (error?.response?.status === 400) {
            toast.error(
              'Something went wrong. Please try again. If the error persists, please contact our support'
            );
          }

          if (error?.response?.status === 401) {
            toast.error('Session expired. Please log in again to continue');

            // redirecting to login after 2 seconds
            setTimeout(() => {
              redirectToLogin();
            }, 2000);
          }
        });
      const new_book_submission = {
        isArchived: false,
        priority: 'Available Material',
        status: 'PROCESSING',
        type: 'BOOK',
        title: selectedFile.name,
        company_name: '',
        note: '',
        created_at: new Date()
      };
      setSubmissionData((prev) => [new_book_submission, ...prev]);
    }
  };

  return (
    <Card className="!pt-0">
      <FileUpload
        title="Upload Book"
        subtitle="Upload a book in .pdf or .docx format and get a coverage template, complete with headers, logline, synopsis, comps, and character breakdown."
        isScript={false}
        setConfirmationModal={setConfirmationModal}
        setReportGenerationModal={setReportGenerationModal}
        reportGeneration={reportGeneration}
        setReportGeneration={setReportGeneration}
        setUploadedFile={setSelectedFile}
      />
      <ConfirmationModal
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
        suretyClickHandler={suretyClickHandler}
      />
      <ReportGenerationModal
        reportGenerationModal={reportGenerationModal}
        setReportGenerationModal={setReportGenerationModal}
        selectedFile={selectedFile}
      />
      <MessageModal
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        title={<span className="lg:text-lg">Processing in the Background</span>}
        message={
          <div className="flex flex-col items-center gap-6 sm:gap-8 md:gap-12 lg:gap-14">
            <p className="font-semibold self-start">
              It&apos;s taking longer than expected. Please check your email for the completed
              report.
            </p>
            <Button
              className="!font-inter !font-semibold"
              onClick={() => {
                setShowMessage(false);
              }}>
              Close
            </Button>
          </div>
        }
        noBottomMargin
      />
    </Card>
  );
};

export default BookCoverage;
